import { useState } from "react";
import styled from "styled-components";
import { Color } from "./Color";

const QandAStyle = styled.div`
  padding: 50px;
  padding-top: 80px;
  .qanda-heading {
    margin-bottom: 50px;
    h1 {
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 20px;
    }
    }
    p {
      font-size: 1em;
      text-align: center;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const ContentStyle = styled.div`
  max-width: 600px;
  margin: auto;
`;

const QuestionStyle = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid rgb(204, 204, 204);
  &:last-child {
    border-bottom: 1px solid rgb(204, 204, 204);
  }
  .question-list {
    position: relative;
    display: flex;
  }
  .question {
    font-weight: 700;
  }
  .index {
    padding-right: 15px;
    font-weight: 700;
    color: ${Color.lightBlue};
  }
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: larger;
  }
  .answer {
    padding-left: 28px;
    margin-top: 20px;
  }
`;

interface questionAndAnswer {
  id: number;
  question: string;
  answer: string;
}

export const QandA = () => {
  const questionAndAnswer: questionAndAnswer[] = [
    {
      id: 1,
      question: "ターゲット企業のリストはどのように作成しますか？",
      answer:
        "弊社が保有する約52,000社の大手・中小企業のデータベースを活用し、お客様ヒアリングした情報をAIに読み込ませ、自動でリストを抽出いたします。",
    },
    {
      id: 2,
      question: "決裁者はどのように特定しますか？",
      answer: "弊社の最新AIを活用して決裁者を特定します。",
    },
    {
      id: 3,
      question: "月間で何件の商談獲得が可能ですか？",
      answer:
        "上場企業役員様も含め、平均して月5〜10件の商談獲得実績がございます。ただ、お客様のご提供するサービスにより変動があります。",
    },
    {
      id: 4,
      question: "商談獲得までの期間はどのくらいですか？",
      answer:
        "ご契約からメールの送付開始まで、リストやメールの作成期間も含めますと約1週間となり、お急ぎの場合は状況に応じて柔軟にご対応させて頂きますので別途ご相談ください。",
    },
    {
      id: 5,
      question: "受注率はどのくらいですか？",
      answer:
        "受注率が高いお客様だと20〜30%になる場合もございますが、商材の内容や単価によっても異なります。",
    },
  ];

  const [openButton1, setOpenButton1] = useState(false);
  const [openButton2, setOpenButton2] = useState(false);
  const [openButton3, setOpenButton3] = useState(false);
  const [openButton4, setOpenButton4] = useState(false);
  const [openButton5, setOpenButton5] = useState(false);
  const handleClick1 = () => {
    setOpenButton1((prev) => !prev);
  };
  const handleClick2 = () => {
    setOpenButton2((prev) => !prev);
  };
  const handleClick3 = () => {
    setOpenButton3((prev) => !prev);
  };
  const handleClick4 = () => {
    setOpenButton4((prev) => !prev);
  };
  const handleClick5 = () => {
    setOpenButton5((prev) => !prev);
  };
  const openButtonList = [
    openButton1,
    openButton2,
    openButton3,
    openButton4,
    openButton5,
  ];
  const handleClickList = [
    handleClick1,
    handleClick2,
    handleClick3,
    handleClick4,
    handleClick5,
  ];

  return (
    <QandAStyle>
      <div className="qanda-heading">
        <h1>よくある質問</h1>
        <p>Q&A</p>
      </div>
      <ContentStyle>
        {questionAndAnswer.map((e) => {
          return (
            <QuestionStyle key={e.id}>
              <div className={"question-list"}>
                <div className="index">{e.id}.</div>
                <div className="question">{e.question}</div>
                {!openButtonList[e.id - 1] && (
                  <button onClick={handleClickList[e.id - 1]}>+</button>
                )}
                {openButtonList[e.id - 1] && (
                  <>
                    <button onClick={handleClickList[e.id - 1]}>-</button>
                  </>
                )}
              </div>
              {openButtonList[e.id - 1] && (
                <div className="answer">{e.answer}</div>
              )}
            </QuestionStyle>
          );
        })}
      </ContentStyle>
    </QandAStyle>
  );
};
