import { motion } from "framer-motion";
import styled from "styled-components";
// import logoTransperant from "../../image/MDLogoTransparent.png";
import logoBlack from "../../image/logoBlack.jpg";

const logoTransition = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transition: { duration: 1.75, ease: [0.87, 0, 0.13, 1] },
    transitionEnd: { display: "none" },
  },
};

const LogoStyle = styled.div`
  background-color: black;
  position: fixed;
  height: 100vh;
  width: 100vw;
  img {
    max-height: 200px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Logo = () => {
  return (
    <motion.div
      variants={logoTransition}
      initial="initial"
      animate="animate"
      onAnimationStart={() => (document.body.style.overflow = "hidden")}
      onAnimationComplete={() => (document.body.style.overflow = "visible")}
    >
      <LogoStyle>
        <img src={logoBlack} alt="logo" />
      </LogoStyle>
    </motion.div>
  );
};
