import styled from "styled-components";
import LandingPageTop from "../../image/LandingPageTop.jpg";
import LandingPageTopLink from "../../image/LandingPageTopLink.jpg";
import { Color } from "./Color";

const TopPageStyle = styled.div`
  margin: 50px 50px;
  span {
    color: ${Color.red};
  }
  .tag {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      border: 1px solid grey;
      border-radius: 10px;
      padding: 0 7px;
      margin: 0;
    }
  }
  .top-page-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }

    .overlay-image {
      position: absolute;
      bottom: 10%;
      left: 10%;
      max-width: 20%;
    }
  }

  @media (max-width: 768px) {
    margin: 50px 0px 0px 0px;
  }
`;

export const TopPage = () => {
  return (
    <TopPageStyle>
      <div className="top-page-image">
        <img src={LandingPageTop} alt="top-page" />
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe1wKclcNzvnIbrI3xsj6kAB2r8NFeSP8rKTuS6qzqlgTDkrg/viewform"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={LandingPageTopLink}
            alt="overlay"
            className="overlay-image"
          />
        </a>
      </div>
    </TopPageStyle>
  );
};
