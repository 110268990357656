import styled from "styled-components";

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 100vh;
  @media screen and (max-width: 630px) {
    background-color: rgba(255, 255, 255, 0.99);
  }
`;

const Content = styled.div`
  padding: 5rem;
`;

const AboutUsTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  img {
    width: 20px;
    height: 20px;
    margin: 0;
  }
  p {
    font-size: 3rem;
    margin: 0;
  }
`;

const CompanyTable = styled.div`
  margin: 3rem;
  margin-bottom: 7rem;
  @media screen and (max-width: 550px) {
    margin: 3rem 1rem;
  }
`;

const CompanyTableRow = styled.div`
  display: flex;
  padding: 20px;
  border-top: 1px solid rgb(204, 204, 204);
  :last-child {
    border-bottom: 1px solid rgb(204, 204, 204);
  }
  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
  }
  @media screen and (max-width: 630px) {
    padding: 10px;
  }
`;

const CompanyTableKey = styled.div`
  width: 10em;
  font-weight: 700;
`;

const CompanyTableValue = styled.div``;

const CompanyTableValueURL = styled.a``;

const MessageName = styled.p`
  padding-left: calc(20px + 2rem);
`;

const MessageImage = styled.img`
  margin: 2rem 0;
  width: 100%;
`;

const MessageWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: end;
`;

const MessageContent = styled.div`
  width: 500px;
  line-height: 2;
`;

const s = {
  Wrapper,
  Content,
  AboutUsTitle,
  CompanyTable,
  CompanyTableRow,
  CompanyTableKey,
  CompanyTableValue,
  CompanyTableValueURL,
  MessageName,
  MessageImage,
  MessageWrapper,
  MessageContent,
};

export default s;
