import { Header } from "./LandingPageHeder";
import { Footer } from "./LandingPageFooter";
import { TopPage } from "./LandingPageTop";
import { Concern } from "./LandingPageConcern";
import { Solution } from "./LandingPageSolution";
import { Case } from "./LandingPageCase";
import { Voc } from "./LandingPageVoc"
import { QandA } from "./LandingPageQandA"
import { Contact } from "./LandingPageContact"
import { Agency } from "./LandingPageAgency"

const LandingPage = () => {
  return (
    <>
      <section id="top">
      </section>
      <Header></Header>
      <TopPage></TopPage>
      <section id="about">
      <Concern></Concern>
      <Solution></Solution>
      <section id="case">
      <Case></Case>
      </section>
      <section id="voc">
      <Voc></Voc>
      </section>
      <section id="agency">
      <Agency></Agency>
      <QandA></QandA>
      </section>
      <section id="contact">
      <Contact></Contact>
      </section>
      </section>
      <Footer></Footer>
    </>
  );
};

export default LandingPage;
