import styled from "styled-components";
import { Color } from "./Color";
import { useEffect, useState } from "react";
import logoTransparent from "../../image/diffferentSalesLogo.png";

const HomeContentStyle = styled.div`
  scroll-behavior: smooth;
  header {
    margin: auto;
    padding: 0 50px;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${Color.navy};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    a {
      color: white;
      text-decoration: none;
      margin-left: 20px;
      position: relative;

      &:hover::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: white;
        transition: width 0.3s;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
  .markdoor {
    font-size: 30px;
    font-weight: 600;
    max-height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    img {
      max-height: 150px;
      width: auto;
    }
  }
  .link-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-right: 100px;
  }


  @media screen and (max-width: 768px) {
    header {
      padding: 10px;
    }

    .markdoor img {
      max-height: 70px;
      width: auto;
    }

    .link-container {
      display: none;
    }
  }
`;

export const Header = () => {
  const [, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  const handleSmoothScroll = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href")!.slice(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <HomeContentStyle>
      <header>
        <a href="#top" className="markdoor" onClick={handleSmoothScroll}>
          <img src={logoTransparent} alt="diffferentsales" />
        </a>
        <div className="link-container">
          <a href="#about" onClick={handleSmoothScroll}>diffferent salesとは</a>
          <a href="#case" onClick={handleSmoothScroll}>導入事例</a>
          <a href="#voc" onClick={handleSmoothScroll}>クライアント様のお声</a>
          <a href="#agency" onClick={handleSmoothScroll}>代理店企業様向け</a>
          <a href="#contact" onClick={handleSmoothScroll}>お問い合わせ</a>
        </div>
      </header>
    </HomeContentStyle>
  );
};
