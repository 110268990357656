import React from 'react';
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import "../App.css";

const app = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { delay: 1.75, duration: 1 },
  },
};

const LpLayout: React.FC = () => {
  return (
    <>
      <motion.div className="AppLp" variants={app} animate="animate" initial="initial">
        <Outlet />
      </motion.div>
    </>
  );
};

export default LpLayout;