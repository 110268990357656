import styled from "styled-components";

interface Props {
  fontSize?: number;
  fontWeight?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  whiteSpace?: string;
}

const TextStyle = styled.div<Props>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : undefined)}rem;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : undefined)};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : undefined)}px;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : undefined}px;
  margin-left: ${(props) =>
    props.marginLeft ? props.marginLeft : undefined}px;
  margin-right: ${(props) =>
    props.marginRight ? props.marginRight : undefined}px;
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : undefined)};
`;

const s = { TextStyle };

export default s;
