import { HomeCareers } from "./HomeCareers";
import { HomeContact } from "./HomeContact";
import { HomeInformation } from "./HomeInformation";
import { HomeMission } from "./HomeMission";
import { HomeService } from "./HomeService";
import { HomeStyle } from "./HomeStyle";
import { HomeTop } from "./HomeTop";

type homeType = {
  id: number;
  component: JSX.Element;
  backgroundColor: string;
};

const home: homeType[] = [
  {
    id: 0,
    component: <HomeMission />,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  {
    id: 1,
    component: <HomeService />,
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  {
    id: 2,
    component: <HomeInformation />,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  {
    id: 3,
    component: <HomeCareers />,
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  {
    id: 4,
    component: <HomeContact />,
    // backgroundColor: "rgb(235, 164, 82)",
    backgroundColor: "rgb(255,193,75)",
  },
];

export const Home: React.FC = () => {
  return (
    <>
      <HomeTop />
      {home.map((e) => {
        return (
          <HomeStyle key={e.id} backgroundColor={e.backgroundColor}>
            {e.component}
          </HomeStyle>
        );
      })}
    </>
  );
};
