import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import ring from "../../image/ring.png";
// import { Link } from "react-router-dom";
// import arrow from "../../image/arrow.png";

const HomeInformationStyle = styled.div`
  padding-bottom: 100px;
  .title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid grey;
    position: relative;
  }
  .title-left {
    margin-left: 5rem;
  }
  .information-head {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-left: 5px;
    }
  }
  .ring {
    img {
      width: 10px;
    }
  }
  p {
    white-space: nowrap;
    overflow: hidden;
  }
  .information {
    color: white;
    font-size: 12rem;
    font-weight: lighter;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1em;
    p {
      margin: 0;
    }
  }
  .slider-title {
    color: white;
    font-size: 12rem;
    font-weight: lighter;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1em;
    p {
      animation: information 2s;
      margin: 0;
    }
  }
  @keyframes information {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .post {
    border-bottom: solid 1px gray;
    margin-top: 30px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 50px;
    a {
      color: black;
      text-decoration: none;
      &:hover {
        color: #a9a8a8;
      }
    }
  }
  .post:last-child {
    margin-bottom: 0;
  }
  .view-more {
    position: absolute;
    right: 0%;
    top: 50%;
    transform: translate(-50%, -50%);

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: black;
      padding: 0 5px;
    }
    img {
      max-width: 15px;
      margin: 0;
      display: block;
    }
  }
  .arrow-container {
    background-color: black;
    padding: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view-more:hover {
    a {
      padding: 0;
    }
    .arrow-container {
      height: 45px;
      width: 45px;
    }
  }
  @media screen and (max-width: 400px) {
    .title-left {
      margin-left: 5rem;
    }
    .post {
      margin-top: 15px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;

const posts = [
  {
    preview: "新プロダクトをリリース　ポイントエコノミー×Web3の”Tips”",
    link: "https://prtimes.jp/main/html/rd/p/000000004.000102516.html",
  },
  {
    preview: "タレント彦摩呂のNFTプロジェクト”NEO HOOD ANIMAL”をリリース",
    link: "https://prtimes.jp/main/html/rd/p/000000003.000102516.html",
  },
  {
    preview:
      "最大手芸能プロダクションである株式会社太田プロダクションと業務提携を開始",
    link: "https://prtimes.jp/main/html/rd/p/000000002.000102516.html",
  },
  {
    preview: "Mark DoorがRKB毎日放送にて特集",
    link: "https://rkb.jp/article/132075/",
  },
  {
    preview:
      "タレントのコージ・トクダ所属の福岡発アメリカンフットボールチームと業務提携を開始",
    link: "https://fukuoka-suns.net/2022/05/16/nft/",
  },
];

export const HomeInformation = () => {
  const { ref, inView } = useInView();
  return (
    <HomeInformationStyle id="information">
      <div className="title">
        <div className="title-left">
          <div className="information-head">
            <div className="ring">
              <img src={ring} alt="ring" />
            </div>
            <p>Information</p>
          </div>

          <p>最新情報をお知らせいたします。</p>
        </div>
        {/* <div className="view-more">
            <Link to={"/information/"}>
              <p>view more</p>
              <div className="arrow-container">
                <img src={arrow} alt="arrow" />
              </div>
            </Link>
          </div> */}
        <div ref={ref} className={inView ? "slider-title" : "information"}>
          <p>Information</p>
        </div>
      </div>
      <div className="posts">
        {posts.map((e) => {
          return (
            <div className="post" key={e.preview}>
              <a href={e.link} target="_blank" rel="noopener noreferrer">
                {e.preview}
              </a>
            </div>
          );
        })}
      </div>
    </HomeInformationStyle>
  );
};
