import styled from "styled-components";
import { Color } from "./Color";
import LandingPageCase1 from "../../image/LandingPageCase1.png";
import LandingPageCase2 from "../../image/LandingPageCase2.png";
import LandingPageCase3 from "../../image/LandingPageCase3.png";

const CaseStyle = styled.div`
  margin: 50px 20px;
  padding: 30px 20px;
  text-align: center;

  h1 {
    text-align: center;
    font-size: 2.0em;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      font-size: 3.0em;
    }
  }

  .case-container-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
    border-radius: 10px;
    padding: 20px;
    overflow: hidden;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      padding: 10px;
      text-align: center;
      margin-bottom: 0px;
    }
  }

  .case-container-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
    border-radius: 10px;
    padding: 20px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      text-align: left;
      margin-bottom: 0px;
      
    }
  }

  .case-bg-right,
  .case-bg-left {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    border-radius: 10px;
    z-index: -1;

    @media (max-width: 768px) {
      width: 100%;
      height: 50%;
    }
  }

  .case-bg-right {
    right: -20%;
    background-color: rgba(173, 216, 230, 0.8);

    @media (max-width: 768px) {
      left: 40%;
    }
  }

  .case-bg-left {
    left: -20%;
    background-color: rgba(173, 216, 230, 0.8);

    @media (max-width: 768px) {
      left: -40%;
    }
  }

  .case-item {
    border-radius: 10px;
    padding: 20px;
    width: 45%;
    color: black;
    text-align: left;
    margin: 10px;
    z-index: 1;

    img {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 20px;
    }

    h2 {
      margin-top: 0;
      font-size: 1.5em;
      color: ${Color.black};
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 30px;

      &::after {
        content: "";
        display: block;
        width: 8%;
        height: 2px;
        background-color: ${Color.black};
        position: absolute;
        bottom: 0;
        left: 0;
      }

      @media (max-width: 768px) {
        font-size: 2.0em;
      }
    }

    ul {
      list-style-type: none;
      padding: 10px;
      border: 2px dashed rgba(0, 0, 255, 0.8);
      border-radius: 5px;
      margin-top: 10px;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }

    li {
      margin: 5px 0;
      display: flex;
      align-items: center;

      &::before {
        content: "•";
        color: ${Color.lightBlue};
        margin-right: 10px;
      }

      @media (max-width: 768px) {
        font-size: 1.3em;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    margin: 0px 20px;
    padding: 0px;
  }
`;

export const Case = () => {
  return (
    <CaseStyle>
      <h1>導入事例（販売サービス）</h1>

      <div className="case-container-right">
        <div className="case-bg-right"></div>
        <div className="case-item">
          <h2>国内クライアント発掘</h2>
          <ul>
            <li>自社ITサービスの販売</li>
            <li>自社開発ハード機器の販売</li>
            <li>他社商品の代理店業務としての利用</li>
            <li>M&A支援を必要としている企業様</li>
            <li>コンサルティングサービスの販売</li>
          </ul>
        </div>
        <div className="case-item">
          <img src={LandingPageCase1} alt="国内クライアント発掘" />
        </div>
      </div>

      <div className="case-container-left">
        <div className="case-bg-left"></div>
        <div className="case-item">
          <img src={LandingPageCase2} alt="国内代理店発掘" />
        </div>
        <div className="case-item">
          <h2>国内代理店発掘</h2>
          <ul>
            <li>自社ITサービスの代理店発掘</li>
            <li>自社to C向け商材ブランドの代理店発掘</li>
            <li>飲食店ブランドの代理店発掘</li>
          </ul>
        </div>
      </div>

      <div className="case-container-right">
        <div className="case-bg-right"></div>
        <div className="case-item">
          <h2>海外市場の進出支援</h2>
          <ul>
            <li>国内大手企業様の海外市場への進出支援</li>
            <li>海外上場企業様の日本市場への進出支援</li>
            <li>海外スタートアップの日本市場への進出支援</li>
          </ul>
        </div>
        <div className="case-item">
          <img src={LandingPageCase3} alt="海外市場の進出支援" />
        </div>
      </div>
    </CaseStyle>
  );
};

export default Case;
