import { useEffect, useState } from "react";
import styled from "styled-components";

const VocStyle = styled.div`
  padding: 50px;
  padding-top: 20px;
  .voc-heading {
    margin-bottom: 50px;
    h1 {
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 20px;
    }
    
    @media (max-width: 768px) {
      margin-bottom: 30px;
      font-size: 1.2em;
    }
  }
  .voc-list {
    ul {
      display: flex;
      gap: 30px;
      list-style: none;
      padding-left: 0;
      margin: 0;
    }
    li {
      flex: 1 1 0;
      img {
        width: 25px;
        margin-bottom: 5px;
      }
      p {
        margin: 0;
        font-weight: 700;
      }
    }
  }
  .company-list {
    ul {
      display: flex;
      gap: 30px;
      list-style: none;
      padding-left: 0;
    }
    li {
      flex: 1 1 0;
      p {
        margin: 0;
        color: rgb(155, 155, 155);
      }
    }
  }
  .voc-list-under-765 {
    padding-top: 0px;
    ul {
      list-style: none;
      p:nth-child(1) {
        font-weight: 700;
        margin-bottom: 0px;
        margin-top: 0px;
      }
      p:nth-child(2) {
        color: rgb(195, 195, 195);
        margin-top: 5px;
        margin-bottom: 30px;
      }

    }
  }
  @media (max-width: 768px) {
    padding: 20px;
    padding-top: 0px;
  }
`;

export const Voc = () => {
  const [isUnder765, setIsUnder765] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 765) {
      setIsUnder765(true);
    } else {
      setIsUnder765(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return (
    <VocStyle>
      <div className="voc-heading">
        <h1>クライアント様のお声</h1>
      </div>
      {!isUnder765 && (
        <>
          <div className="voc-list">
            <ul>
              <li>
                <p>
                  社長役員アポ数の獲得割合が2%を超えており、商談の中でも決裁者様に直接アプローチできるため、商談成約までのスピードが早い。
                </p>
              </li>
              <li>
                <p>
                  This service has been instrumental in both boosting our sales and cutting costs, as it allowed us to secure Japanese corporate clients before we even established our branch in Japan to enter the market.
                </p>
                <p>
                  （このサービスのおかげで、日本支社を設立して市場に参入する前に、日本の法人顧客を確保することができ、売上の向上とコスト削減の両方に役立った。）
                </p>
              </li>
            </ul>
          </div>
          <div className="company-list">
            <ul>
              <li>
                <p className="company">― プライム上場製造業様</p>
              </li>
              <li>
                <p className="company">― 米スタートアップ企業</p>
              </li>
            </ul>
          </div>
        </>
      )}
      {isUnder765 && (
        <>
          <div className="voc-list-under-765">
            <ul>
              <li>
                <p>
                  社長役員アポ数の獲得割合が2%を超えており、商談の中でも決裁者様に直接アプローチできるため、商談成約までのスピードが早い。
                </p>
                <p>― プライム上場製造業様</p>
              </li>
              <li>
                <p>This service has been instrumental in both boosting our sales and cutting costs, as it allowed us to secure Japanese corporate clients before we even established our branch in Japan to enter the market.<br/>（このサービスのおかげで、日本支社を設立して市場に参入する前に、日本の法人顧客を確保することができ、売上の向上とコスト削減の両方に役立った。）</p>
                <p>― 米スタートアップ企業</p>
              </li>
            </ul>
          </div>
        </>
      )}
    </VocStyle>
  );
};
