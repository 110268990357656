import { Link } from "react-router-dom";
import { HamburgerMenu } from "./HamburgerMenu";
import s from "./NavigationStyle";
import logo from "../image/MDLogoTransparent.png";

export const Navigation: React.FC = () => {
  return (
    <s.NavigationStyle>
      <nav>
        <s.Icon>
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
        </s.Icon>
        <s.WideMenuStyle>
          <ul>
            <li>
              <Link to={"/aboutus"} className="notActive">
                About Us
              </Link>
            </li>
            <li className="link-mission">
              <a href="#mission" className="notActive">
                Mission
              </a>
            </li>
            <li>
              <a href="#service" className="notActive">
                Service
              </a>
            </li>
            <li>
              <a href="#information" className="notActive">
                Information
              </a>
            </li>
            <li>
              <a href="#careers" className="notActive">
                Careers
              </a>
            </li>
            <li>
              <a href="#contact" className="notActive">
                Contact
              </a>
            </li>
          </ul>
        </s.WideMenuStyle>
        <s.HamburgerMenuStyle>
          <HamburgerMenu />
        </s.HamburgerMenuStyle>
      </nav>
    </s.NavigationStyle>
  );
};
