import styled from "styled-components";

interface Props {
  backgroundColor: string;
}

export const HomeStyle = styled.div<Props>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : undefined};
  > div {
    margin-left: 100px;
    margin-right: 100px;
    padding-top: 100px;
  }
  @media screen and (max-width: 1269px) {
    > div {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
  @media screen and (max-width: 600px) {
    > div {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 400px) {
    > div {
      margin-left: 25px;
      margin-right: 25px;
    }
  }
`;
