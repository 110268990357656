import React from 'react';
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Logo } from "../components/logo/Logo";
import { Navigation } from "../components/Navigation";
import { Footer } from "../components/Footer";
import "../App.css";

const app = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { delay: 1.75, duration: 1 },
  },
};

const DefaultLayout: React.FC = () => {
  return (
    <>
      <Logo />
      <motion.div className="AppHp" variants={app} animate="animate" initial="initial">
        <Navigation />
          <Outlet /> 
        <Footer />
      </motion.div>
    </>
  );
};

export default DefaultLayout;