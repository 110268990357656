import styled from "styled-components";
import { Color } from "./Color";
import LandingPageSolutionLeft from "../../image/LandingPageSolutionLeft.png"
import LandingPageSolutionRight from "../../image/LandingPageSolutionRight.png"

const SolutionStyle = styled.div`
  margin: 50px 50px;
  background-color: ${Color.orangeBackground};
  padding: 30px 180px;
  color: ${Color.white};
  text-align: center;

  h1 {
    font-size: 2.0em;
    text-align: center;
    margin 0px;
  }

  p {
    font-size: 1.5em;
    margin-top 20px;
    margin-bottom 10px;
  }

  .solution-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }

  .solution-item {
    background-color: ${Color.white};
    border-radius: 10px;
    padding: 20px;
    width: 45%;
    color: black;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    img {
      width: 70%;
      border-radius: 10px;
    }

    h2 {
      margin-top: 15px;
      font-size: 1.5em;
    }

    p {
      margin-top: 10px;
      font-size: 1em;
      line-height: 1.5;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0px;
    padding: 10px 20px 20px 20px;

    h1 {
      font-size: 2.5em;
    }

    p {
      font-size: 1.7em;
    }

    .solution-container {
      flex-direction: column;
      gap: 10px;
      width: 90%;
    }

    .solution-item {
      width: 100%;
      img {
        width: 80%;
      }

      h2 {
        font-size: 1.4em; 
      }

      p {
        font-size: 1.2em;
      }
    }
  }
`;




export const Solution = () => {
  return (
    <SolutionStyle>
      <p>solution</p>
      <h1>diffferent salesなら</h1>
      <h1>社長/役員様のアポイントを獲得し</h1>
      <h1>その課題、解決します</h1>
      <div className="solution-container">
        <div className="solution-item">
          <img src={LandingPageSolutionLeft} alt="Solution Left" />
          <h2>直接クライアント候補の社長/役員様にアプローチができる</h2>
          <p>
            最初の商談から決裁者様がお話しを聞いていただけるので、
            そもそも成約率も高く、成約までの期間も短くできます。
            またお断りが来た企業様の情報もお伝えするので、
            1ヶ月後などに自社で再アプローチすればアポイントにつながる率も高くなります！
          </p>
        </div>
        <div className="solution-item">
          <img src={LandingPageSolutionRight} alt="Solution Right" />
          <h2>代理店/FC開拓でもご利用いただけます</h2>
          <p>
            決裁者様に直接、商材の代理店としてのお取り扱いを依頼できるので、
            代理店社内での優先度も必然的に上がり、貴社売上に貢献できます。
          </p>
        </div>
      </div>
    </SolutionStyle>
  );
};
