import styled from "styled-components";
import { Color } from "./Color";
import LandingPageAgencyTop from "../../image/LandingPageAgencyTop.png"
import LandingPageAgency1 from "../../image/LandingPageAgency1.png";
import LandingPageAgency2 from "../../image/LandingPageAgency2.png";
import LandingPageAgency3 from "../../image/LandingPageAgency3.png";
import LandingPageAgencyLink from "../../image/LandingPageAgencyLink.png";

const AgencyStyle = styled.div`
  margin: 50px 50px;
  border-radius: 10px;
  padding: 40px 20px;
  color: ${Color.black};

  h1 {
    font-size: 2.5em;
    text-align: left;
    margin-bottom: 20px;
    width: 60%;

    @media (max-width: 768px) {
      font-size: 3.5em;
    }
  }

  .agency-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 30px;
    background-color: ${Color.grayBackground};
  }

  .agency-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${Color.white};
    border-radius: 10px;
    padding: 20px;
    width: 60%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 1.5em;

      @media (max-width: 768px) {
        font-size: 1.8em;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      
      li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        &::before {
          content: "✔";
          color: ${Color.lightBlue};
          margin-right: 10px;
          font-size: 1.2em;
        }

         @media (max-width: 768px) {
          font-size: 1.2em;
        }
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .agency-example {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    padding: 10px 20px;
    background-color: ${Color.blackBackground};

    @media (max-width: 768px) {
      padding: 0px;
    }
  }

  .agency-example-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 60%;
    color: ${Color.white};

    h2 {
      font-size: 1.8em;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        font-size: 2.0em;
      }
    }

    p {
      margin: 0px;
    }

  }

  .image-container {
    width: 30%;
    text-align: center;
    
    img {
      width: 100%;
    }
    
    @media (max-width: 768px) {
      width: 60%;
    }
  }

  .image-example-container {
    text-align: center;
    
    img {
      width: 100%;
    }
  }

  .image-example-container-link {
    text-align: left;
    img {
      width: 50%;
    }
  }

  .image-example-container-base {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    text-align: center;
    gap: 20px;
    margin-bottom: 20px;

    p {
      color: ${Color.white};
      margin: 0px;
    }
  }
  @media (max-width: 768px) {
    margin: 0px 0px;
    padding: 0px;
  }
`;

export const Agency = () => {
    return (
      <AgencyStyle>
        <div className="agency-container">
          <h1>代理店募集中</h1>
          <div className="agency-item">
            <div className="image-container">
              <img src={LandingPageAgencyTop} alt="" />
            </div>
            <div className="text-container">
              <h2>代理店制度とは</h2>
              <ul>
                <li>お知り合いの企業様に弊社サービス簡易的にご紹介いただき、<br/>面談を組んでいただきます。</li>
                <li>ご紹介後はなにもすることがございません！
                <br/>（商談〜契約後のアフターフォローまで弊社で行わせていただきます）</li>
                <li>ご紹介いただい企業様が一定数成約した場合につきましては<br/>報酬還元率を上げさせていただきます！</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="agency-example">
          <div className="agency-example-item">
            <div className="text-container">
              <h2>diffferent salesで新たなストック収入を ! </h2>
              <p>紹介代理店様の営業範囲は、顧客の開拓から弊社へのご紹介までです。</p>
              <p>その後の商品説明から申込獲得、商品発送、アフターフォローはすべて弊社で行わせていただきます。</p>
            </div>
          </div>
          <div className="image-example-container-base">
            <div className="image-example-container">
              <img src={LandingPageAgency1} alt="" />
              <p>1件制約すれば</p>
              <p>黒字になります。</p>
              <p>さらに、年間で2件契約</p>
              <p>できれば、それだけで</p>
              <p>平均年収の1.5倍になり</p>
              <p>ます。</p>
            </div>
            <div className="image-example-container">
              <img src={LandingPageAgency2} alt="" />
              <p>新規開拓はどの企業も</p>
              <p>課題に感じており、</p>
              <p>国内上場企業様など</p>
              <p>ご利用実績も多数あるので</p>
              <p>成約がしやすくなって</p>
              <p>おります。</p>
            </div>
            <div className="image-example-container">
              <img src={LandingPageAgency3} alt="" />
              <p>弊社のサービスの</p>
              <p>類似サービスがまだ存在</p>
              <p>しないので、</p>
              <p>興味を惹きやすい＆</p>
              <p>成約しやすくなっており</p>
              <p>ます。</p>
            </div>
          </div>
          <div className="image-example-container-base">
            <div className="image-example-container-link">
              <a
                href="https://lin.ee/wneRcu6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LandingPageAgencyLink} alt="" />
              </a>
            </div>
          </div>
        </div>
      </AgencyStyle>
    );
  };
