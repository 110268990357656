import styled from "styled-components";

const NavigationStyle = styled.div`
  /* background-color: rgba(89, 175, 252, 0.8); */
  background-color: rgba(255, 255, 255, 0.95);
  nav {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 50px;
    position: relative;
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    gap: 30px;
  }
  a {
    text-decoration: none;
    color: black;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active {
    border-bottom: 2px solid black;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notActive {
    border-bottom: 2px solid transparent;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notActive:hover {
    border-bottom: 2px solid black;
  }
  @media screen and (max-width: 800px) {
    nav {
      height: 80px;
    }
  }
  @media screen and (max-width: 400px) {
    nav {
      margin: auto 25px;
    }
  }
`;

const WideMenuStyle = styled.div`
  @media screen and (max-width: 769px) {
    display: none;
  }
`;

const Icon = styled.div`
  border-bottom: 2px solid transparent;
  margin-right: auto;
  font-size: 25px;
  font-weight: bolder;
  img {
    padding-top: 10px;
    max-height: 130px;
  }
  @media screen and (max-width: 500px) {
    img {
      max-height: 100px;
    }
  }
`;

const HamburgerMenuStyle = styled.div`
  display: none;
  .open-button {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    &:focus {
      outline: none;
    }
  }
  span {
    background-color: black;
    width: 2rem;
    height: 0.2rem;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
  .close-button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }
  .close {
    font-size: 25px;
    margin: auto;
    color: black;
  }
  nav {
    position: relative;
    position: fixed;
    height: 100%;
    /* background-color: rgba(89, 175, 252, 1); */
    background-color: rgba(255, 255, 255, 1);
    width: 300px;
    top: 0;
    right: 0;
    z-index: 9;
    margin: 0;
  }
  ul {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 50px 0;
    width: 100%;
  }
  @media screen and (max-width: 769px) {
    display: block;
  }
`;
const s = { NavigationStyle, WideMenuStyle, HamburgerMenuStyle, Icon };

export default s;
