import styled from "styled-components";
import concern from "../../image/LandingPageConcern.png";
import { Color } from "./Color";

const Wrapper = styled.div`
  margin: 50px 50px;
  border: 5px solid;
  border-color: ${Color.greyBackground};
  border-radius: 5%;
  padding: 10px 0;
  
  h1 {
    text-align: center;
    font-size: 2.8em;
    margin 0px;

    @media (max-width: 768px) {
      font-size: 2.5em;
    }
  }

  p {
    text-align: center;
    font-size: 1.8em;
    margin-top 20px;
    margin-bottom 10px;
    color: ${Color.darkBlue};
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ImgStyle = styled.div`
  text-align: center;
  img {
    max-width: 100%;
  }
`;

const TextStyle = styled.div`
  text-align: center;
  font-size: 2.0em;
  color: ${Color.darkBlue};
  margin-top: 20px;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 10px;
  }

  li::before {
    content: '✓';
    color: ${Color.darkBlue};
    margin-right: 10px;
  }
`;

const s = { Wrapper, ImgStyle, TextStyle };

export const Concern = () => {
  return (
    <s.Wrapper>
      <p>problem</p>
      <h1>こんなお悩みありませんか？</h1>
      <s.ImgStyle>
        <img src={concern} alt="concern" />
      </s.ImgStyle>
      <s.TextStyle>
        <ul>
          <li>案件が成約率が低い</li>
          <li>決済者へのアポが少ない</li>
          <li>営業リソースを増やす資金の余裕がない</li>
        </ul>
      </s.TextStyle>
    </s.Wrapper>
  );
};