import { useState } from "react";
import { Link } from "react-router-dom";

export const HamburgerMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "visible";
  //   }
  // }, [isOpen]);
  return (
    <>
      {!isOpen && (
        <>
          <button className="open-button" onClick={() => setIsOpen(!isOpen)}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </>
      )}

      {isOpen && (
        <>
          <button className="close-button" onClick={() => setIsOpen(!isOpen)}>
            <div className="close">X</div>
          </button>
          <nav>
            <ul>
              <li>
                <Link to={"/aboutus"} onClick={() => setIsOpen(!isOpen)}>
                  About Us
                </Link>
              </li>
              <li>
                <a href="#mission" onClick={() => setIsOpen(!isOpen)}>
                  mission
                </a>
              </li>
              <li>
                <a href="#service" onClick={() => setIsOpen(!isOpen)}>
                  service
                </a>
              </li>
              <li>
                <a href="#information" onClick={() => setIsOpen(!isOpen)}>
                  information
                </a>
              </li>
              <li>
                <a href="#careers" onClick={() => setIsOpen(!isOpen)}>
                  careers
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => setIsOpen(!isOpen)}>
                  contact
                </a>
              </li>
            </ul>
          </nav>
        </>
      )}
    </>
  );
};
