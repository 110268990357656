export const Color = {
    lightBlue: "rgb(55, 201, 240)",
    lightGreen: "rgb(80, 202, 195)",
    navy: "rgb(0, 0, 0, 0.8)",
    greyBackground: "rgb(244, 246, 248)",
    orangeBackground: "rgb(255, 165, 79)",
    lightBlueBackground: "rgb(180, 230, 245)",
    lightOrangeBackground: "rgb(255, 220, 160)",
    grayBackground: "rgb(121, 121, 121)",
    blackBackground: "rgb(0, 0, 0)",
    red: "red",
    white: "white",
    greyText: "rgb(200, 200, 200)",
    black: "black",
    lBlue: "#ADD8E6",
    darkBlue: "rgb(50, 180, 185)",
  };
  