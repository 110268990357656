import { motion } from "framer-motion";
import s from "./TextVerticle.style";

interface Props {
  text: string;
  delayTime: number;
  fontSize?: number;
  fontWeight?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  whiteSpace?: string;
}

export const TextVertical: React.FC<Props> = ({
  text,
  delayTime,
  fontSize,
  fontWeight,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  whiteSpace,
}) => {
  const animateText = {
    initial: { opacity: 0, y: 20 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { delay: delayTime, duration: 0.6 },
    },
  };

  return (
    <s.TextStyle
      fontSize={fontSize}
      fontWeight={fontWeight}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      whiteSpace={whiteSpace}
    >
      <motion.div variants={animateText} initial="initial" animate="animate">
        {text}
      </motion.div>
    </s.TextStyle>
  );
};
