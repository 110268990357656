import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../image/MDLogoTransparent.png";

const FooterStyle = styled.div`
  background-color: rgb(248, 200, 97);
  padding-left: 100px;
  padding-right: 100px;
  .footer-nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .markdoor-icon {
    margin-right: auto;
    font-size: 25px;
    font-weight: bolder;
  }
  ul {
    list-style: none;
    margin: 0;
    display: flex;
    gap: 40px;
    padding: 0;
  }
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .copy-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 900px) {
    padding-left: 35px;
    padding-right: 35px;
    ul {
      gap: 30px;
    }
  }
  @media screen and (max-width: 600px) {
    padding-left: 25px;
    padding-right: 25px;
    .footer-nav-container {
      padding-bottom: 0;
    }
    ul {
      gap: 15px;
    }
  }
  @media screen and (max-width: 450px) {
    ul {
      gap: 10px;
    }
  }
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  margin-right: auto;
  height: 100px;
  img {
    max-height: 100px;
  }

  @media screen and (max-width: 600px) {
    height: 70px;
    img {
      max-height: 70px;
    }
  }
  @media screen and (max-width: 380px) {
    height: 50px;
    img {
      max-height: 50px;
    }
  }
`;

const PrivacyStyle = styled.div`
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = () => {
  return (
    <FooterStyle>
      <div className="footer-nav-container">
        <Icon>
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
        </Icon>
        <nav>
          <ul>
            <li>
              <Link to={"/aboutus"}>About Us</Link>
            </li>
            <li>
              <a href="#mission">Mission</a>
            </li>
            <li>
              <a href="#service">Service</a>
            </li>
            <li>
              <a href="#information">Information</a>
            </li>
            <li>
              <a href="#careers">Careers</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
      <PrivacyStyle>
        <Link to={"/privacy-policy"}>プライバシーポリシー</Link>
      </PrivacyStyle>
      <div className="copy-right">© Mark Door Inc.</div>
    </FooterStyle>
  );
};
