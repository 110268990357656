import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import ring from "../../image/ring.png";
import arrow from "../../image/arrow.png";

const HomeCareersStyle = styled.div`
  margin: auto 100px;
  padding-top: 100px;
  padding-bottom: 100px;
  h1 {
    font-size: 7.5rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 80px;
    font-weight: lighter;
  }
  .careers-head {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-left: 5px;
    }
  }
  .ring {
    img {
      width: 10px;
    }
  }
  .h1-not-inview {
    overflow: hidden;
    white-space: nowrap;
    p {
      margin: 0;
    }
  }
  .h1inview {
    overflow: hidden;
    white-space: nowrap;
    p {
      margin: 0;
      animation: careerslide;
      animation-duration: 1s;
    }
  }
  @keyframes careerslide {
    0% {
      transform: translateX(10%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  .text-container {
    display: flex;
    align-items: center;
  }
  .career-text {
    padding-left: 50px;
    width: 70%;
    white-space: pre-wrap;
  }
  .view-more {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: black;
    }
    img {
      max-width: 15px;
      margin: 0;
      display: block;
    }
  }
  .arrow-container {
    background-color: black;
    padding: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view-more:hover {
    .arrow-container {
      height: 45px;
      width: 45px;
    }
  }
  @media screen and (max-width: 900px) {
    .career-text {
      padding-left: 0;
    }
  }
  @media screen and (max-width: 830px) {
    h1 {
      font-size: 6rem;
    }
    .career-text {
      width: 75%;
    }
    .view-more {
      flex: 1;
    }
  }
  @media screen and (max-width: 600px) {
    h1 {
      margin-bottom: 30px;
    }
    .text-container {
      display: block;
    }
    .career-text {
      margin: auto;
      text-align: center;
      width: 100%;
    }
    .view-more {
      margin: auto;
      margin-top: 30px;
      img {
        max-width: 10px;
      }
    }
    .arrow-container {
      height: 20px;
      width: 20px;
    }
  }

  @media screen and (max-width: 400px) {
    .arrow-container {
      height: 20px;
      width: 20px;
    }
    .view-more {
      img {
        max-width: 10px;
      }
    }
  }
`;

export const HomeCareers = () => {
  const { ref, inView } = useInView();
  return (
    <HomeCareersStyle id="careers">
      <div className="careers-head">
        <div className="ring">
          <img src={ring} alt="ring" />
        </div>
        <p>Careers</p>
      </div>
      <h1 ref={ref} className={inView ? "h1inview" : "h1-not-inview"}>
        <p>Join Playmates</p>
      </h1>
      <div className="text-container">
        <p className="career-text">
          {`私たちは自立分散型の組織であり、やりたいことをやれるための\nプラットフォームでありたいと思っています。最低限の生活は\n保証される中で好きなことでクライアント様の役に立つ。\nやった分だけ感謝され、対価を貰う平等な組織です。\nそんなステージの上で共に輝ける仲間を募集しています。`}
        </p>
        <div className="view-more">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSe1wKclcNzvnIbrI3xsj6kAB2r8NFeSP8rKTuS6qzqlgTDkrg/viewform">
            <p>Contact Us</p>
            <div className="arrow-container">
              <img src={arrow} alt="arrow" />
            </div>
          </a>
        </div>
      </div>
    </HomeCareersStyle>
  );
};
