import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Home } from "./components/Home/Home";
import AboutUs from "./components/AboutUs/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import LandingPage from "./components/LandingPage/LandingPage";
import HomePageLayout from "./Layout/HomePageLayout";
import LandingPageLayout from "./Layout/LandingPageLayout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Default Layout Routes */}
        <Route element={<HomePageLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* 
          <Route path={"/mission/"} element={<Mission />}></Route>
          <Route path={"/service/"} element={<Service />}></Route>
          <Route path={"/information/"} element={<Information />}></Route>
          <Route path={"/careers/"} element={<Careers />}></Route>
          <Route path={"/contact/"} element={<Contact />}></Route> 
          */}
        </Route>

        {/* LP Layout Route */}
        <Route element={<LandingPageLayout />}>
          <Route path="/lp" element={<LandingPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
