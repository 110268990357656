import {
  abstractText,
  date,
  privacyPolicyContent,
} from "./PrivacyPolicyContent";
import s from "./PrivacyPolicy.styles";

const PrivacyPolicy = () => {
  return (
    <s.Wrapper>
      <s.Content>
        <s.Title>
          <p>プライバシーポリシー</p>
        </s.Title>
        <s.AbstractText>
          <p>{abstractText}</p>
        </s.AbstractText>
        {privacyPolicyContent.map((element) => {
          return (
            <>
              <s.ContentHeader>{element.header}</s.ContentHeader>

              <p>{element.content}</p>
              {element.list.length !== 0 ? (
                <>
                  {element.list.map((listContent) => {
                    return <s.ContentList>{listContent}</s.ContentList>;
                  })}
                </>
              ) : (
                <></>
              )}
            </>
          );
        })}
        <s.DateWrapper>
          {date.map((dateElement) => {
            return <p>{dateElement}</p>;
          })}
        </s.DateWrapper>
      </s.Content>
    </s.Wrapper>
  );
};

export default PrivacyPolicy;
