import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import arrow from "../../image/arrow.png";

const HomeContactStyle = styled.div`
  padding-bottom: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  .contact-inview {
    position: absolute;
    font-size: 12rem;
    font-weight: lighter;
    color: rgb(251, 200, 106);
    margin: 0;
    line-height: 1em;
    animation: contact 1s;
    white-space: nowrap;
    overflow: hidden;
  }
  @keyframes contact {
    0% {
      transform: translateX(-20%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  .contact-text {
    z-index: 2;
  }
  .view-more {
    z-index: 2;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: black;
    }
    img {
      max-width: 15px;
      margin: 0;
      display: block;
    }
  }
  .arrow-container {
    background-color: black;
    padding: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view-more:hover {
    .arrow-container {
      height: 45px;
      width: 45px;
    }
  }
  @media screen and (max-width: 800px) {
    .contact-inview {
      font-size: 10rem;
    }
  }
  @media screen and (max-width: 600px) {
    gap: 20px;
    .arrow-container {
      height: 20px;
      width: 20px;
    }
    .view-more {
      img {
        max-width: 10px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .arrow-container {
      height: 15px;
      width: 15px;
    }
    .view-more {
      img {
        max-width: 10px;
      }
    }
    .view-more {
      left: 15%;
    }
    .contact-text {
      right: 10%;
    }
  }
`;

export const HomeContact = () => {
  const { ref, inView } = useInView();
  return (
    <HomeContactStyle id="contact">
      <div ref={ref} className={inView ? "contact-inview" : "contact-notview"}>
        Contact us
      </div>
      <div className="view-more">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSe1wKclcNzvnIbrI3xsj6kAB2r8NFeSP8rKTuS6qzqlgTDkrg/viewform">
          <p>Contact Us</p>
          <div className="arrow-container">
            <img src={arrow} alt="arrow" />
          </div>
        </a>
      </div>
      <div className="contact-text">
        みなさまからのご質問・ご質問を受け付けています。<br></br>
        お問い合わせについては、こちらにお送りください。
      </div>
    </HomeContactStyle>
  );
};
