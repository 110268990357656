import { motion } from "framer-motion";

const animateParent = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 2,
      staggerChildren: 0.05,
    },
  },
};

const animateChild = {
  visible: {
    opacity: 1,
    transition: { type: "spring", damping: 12, stiffness: 100 },
  },
  hidden: {
    opacity: 0,
    transition: { type: "spring", damping: 12, stiffness: 100 },
  },
};

interface Props {
  text: string;
}

export const AnimatedText: React.FC<Props> = ({ text }) => {
  const characters: string[] = text.split("");
  return (
    <motion.div variants={animateParent} initial="hidden" animate="visible">
      <h1>
        {characters.map((e) => {
          return (
            <motion.span variants={animateChild} key={e}>
              {e}
            </motion.span>
          );
        })}
      </h1>
    </motion.div>
  );
};
