import styled from "styled-components";

const FooterStyle = styled.div`
  height: 100px;
  background-color: rgb(16, 38, 65);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = () => {
  return (
    <FooterStyle>
      <div>©Copyright - MarkDoor</div>
    </FooterStyle>
  );
};
