import s from "./AboutUs.styles";
import ring from "../../image/ring.png";

const companyContent = [
    {
        key: "会社名",
        value: "Mark Door Inc.",
    },
    {
        key: "本社",
        value: "〒532-0011 大阪府大阪市淀川区西中島６丁目２番３号",
    },
    {
        key: "開発拠点",
        value: "Number One, Pink, MediaCity UK, Salford, United Kingdom",
    },
    {
        key: "会社設立",
        value: "2021年 10月28日",
    },
    {
        key: "URL",
        value: "https://markdoor.net/",
    },
    {
        key: "代表取締役",
        value: "松田 光弘",
    },
    {
        key: "顧問税理士事務所",
        value: "エンジョイント税理士法人",
    },
    {
        key: "顧問弁護士事務所",
        value: "福岡つむぎ法律事務所",
    },
    {
        key: "事業内容",
        value: "セールステック事業/FAS事業/M&A事業/受託開発事業",
    },
];

const AboutUs = () => {
    return (
        <s.Wrapper>
            <s.Content>
                <s.AboutUsTitle>
                    <img src={ring} alt="ring" />
                    <p className="mission-title">About Us</p>
                </s.AboutUsTitle>
                <s.CompanyTable>
                    {companyContent.map((element) => {
                        return (
                            <s.CompanyTableRow>
                                <s.CompanyTableKey>{element.key}</s.CompanyTableKey>
                                {element.key !== "URL" ? (
                                    <s.CompanyTableValue>{element.value}</s.CompanyTableValue>
                                ) : (
                                    <s.CompanyTableValueURL href={element.value}>
                                        {element.value}
                                    </s.CompanyTableValueURL>
                                )}
                            </s.CompanyTableRow>
                        );
                    })}
                </s.CompanyTable>
            </s.Content>
        </s.Wrapper>
    );
};

export default AboutUs;
