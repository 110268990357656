import styled from "styled-components";
import { Color } from "./Color";

const ContactStyle = styled.div`
  padding: 50px;
  padding-top: 80px;
  .contact-heading {
    margin-bottom: 50px;
    h1 {
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 20px;
    }

    p {
      font-size: 1em;
      text-align: center;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 1.3em;
      }
    }

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  .contact-button {
    display: block;
    width: 33.33%;
    margin: 30px auto 0;
    padding: 15px 30px;
    font-size: 1.2em;
    color: ${Color.white};
    background-color: ${Color.lightBlue};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: ${Color.darkBlue};
    }
    
    @media (max-width: 768px) {
      padding: 10px 30px;
      font-size: 1.1em;
      margin: 0px auto 0;
    }
  }
  
  @media (max-width: 768px) {
    padding-top: 0px;
  }
`;


export const Contact = () => {
  return (
    <ContactStyle>
      <div className="contact-heading">
        <h1>お問い合わせ</h1>
        <p>みなさまからのご質問・ご質問を受け付けています。</p>
        <p>お問い合わせについては、こちらにお送りください。</p>
      </div>
      <a
        className="contact-button"
        href="https://docs.google.com/forms/d/e/1FAIpQLSe1wKclcNzvnIbrI3xsj6kAB2r8NFeSP8rKTuS6qzqlgTDkrg/viewform"
        target="_blank"
        rel="noopener noreferrer"
      >
        お問い合わせフォームへ
      </a>
    </ContactStyle>
  );
};
