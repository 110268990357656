import styled from "styled-components";

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 100vh;
  @media screen and (max-width: 630px) {
    background-color: rgba(255, 255, 255, 0.99);
  }
`;

const Content = styled.div`
  padding: 5rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 2rem;
    margin: 0;
  }
`;

const AbstractText = styled.div`
  padding-top: 1rem;
`;

const ContentHeader = styled.h4`
  margin: 2rem 0 0 0;
`;

const ContentList = styled.p`
  margin: 0;
`;

const DateWrapper = styled.div`
  margin-top: 3rem;
`;

const s = {
  Wrapper,
  Content,
  Title,
  AbstractText,
  ContentHeader,
  ContentList,
  DateWrapper,
};

export default s;
